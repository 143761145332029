import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询故障告警列表
export function listAlert(query: any) {
  return request({
    url: "/factory/alert/list",
    method: "get",
    params: query,
  });
}

// 查询故障告警详细
export function getAlert(query: any) {
  return request({
    url: "/factory/alert/" + query.id,
    method: "get",
    params: query,
  });
}

// 新增故障告警
export function addAlert(data: any) {
  return request({
    url: "/factory/alert",
    method: "post",
    data: data,
  });
}

// 修改故障告警
export function updateAlert(data: any) {
  return request({
    url: "/factory/alert",
    method: "put",
    data: data,
  });
}

// 删除故障告警
export function delAlert(data: any) {
  return request({
    url: "/factory/alert/" + data.id,
    method: "delete",
    data: data,
  });
}

// 统计数据
export function summary() {
  return request({
    url: "/factory/alert/summary",
    method: "get",
  });
}
